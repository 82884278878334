import React from 'react';
import { Trans } from 'react-i18next';

import { LOADING_OPTIONS, LinkWrapper, Text } from '@components';
import { COMPATIBLE_DEVICES } from '@shared/constants/pages/product-page-common';
import { META_IMAGE, PRICING_LINK } from '@shared/constants/constants';
import { APPS_LINKS } from '@shared/constants/apps';
import { SERVER_COUNT } from '@shared/constants/server-locations';
import { formatLink } from '@shared/format-helpers';
import { APP_PARAMS, GA4_EVENTS } from '@shared/constants/google';
import { getFetchedImage } from '@shared/image-helpers';
// Footer CTA images
import footerImage from '@images/product-page/common-footer-image.png';
import footerImageTablet from '@images/product-page/common-footer-image-tablet.png';
import footerImageTabletTop from '@images/product-page/common-footer-image-tablet-top.png';
import footerImageMobile from '@images/product-page/common-footer-image-mobile.png';
import footerImageMobileTop from '@images/product-page/common-footer-image-mobile-top.png';
// Steps Images
import Subscribe from '@images/product-page/upgrade-anytime.svg';
import DownloadRepository from '@images/product-page/download-deb.svg';
import SignInAndConnect from '@images/product-page/sign-in-and-connect.svg';
// Perks images
import PrivacyPro from '@images/product-page/privacy-pro.svg';
import SeamlessStreaming from '@images/product-page/seamless-streaming.svg';
import GetOnAllDevices from '@images/product-page/get-on-all-devices.svg';
import PrivateDns from '@images/product-page/private-dns.svg';

const StyledTextComponent = ({ children }) => (
  <Text.Body2 fontFamily="courier" fontStyle="italic">
    {children}
  </Text.Body2>
);

export const PAGE_DATA = ({ images }) => ({
  metaData: (t) => ({
    title: t('vpnForLinux:metaData.title'),
    description: t('vpnForLinux:metaData.description'),
    image: META_IMAGE,
  }),
  headerCta: {
    text: ({ isLoading, discountPercentage, pricingLink = PRICING_LINK, t } = {}) => ({
      title: t('vpnForLinux:headerCta.title'),
      subtitle: t('vpnForLinux:headerCta.subtitle'),
      description: [
        { title: t('vpnForLinux:headerCta.productFeatures.secureVpn') },
        {
          title: t('vpnForLinux:headerCta.productFeatures.ultraFastServers', {
            serverCount: SERVER_COUNT,
          }),
        },
        { title: t('vpnForLinux:headerCta.productFeatures.browsePrivately') },
        { title: t('vpnForLinux:headerCta.productFeatures.easyToUse') },
      ],
      buttons: [
        {
          title: t('common:actions.downloadFree'),
          link: APPS_LINKS.linux,
          buttonProps: {
            variant: 'outlined',
            customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.linux },
          },
        },
      ],
    }),
    image: () => ({
      mobile: {
        src: getFetchedImage(images, 'product-page/linux-header-image-mobile.png'),
        alt: 'linux page header',
        loading: LOADING_OPTIONS.eager,
      },
      tablet: {
        src: getFetchedImage(images, 'product-page/linux-header-image-tablet.png'),
        alt: 'linux page header',
        loading: LOADING_OPTIONS.eager,
      },
      desktop: {
        src: getFetchedImage(images, 'product-page/linux-header-image.png'),
        alt: 'linux page header',
        loading: LOADING_OPTIONS.eager,
        sizes: '2830px',
        position: {
          top: -80,
          height: 720,
          maxWidth: 2830,
        },
      },
    }),
  },
  compatibleDevices: (t) => ({ cards: COMPATIBLE_DEVICES(t), mobileCards: COMPATIBLE_DEVICES(t) }),
  steps: ({ pricingLink = PRICING_LINK, t } = {}) => ({
    title: t('vpnForLinux:stepCards.title'),
    description: t('vpnForLinux:stepCards.description'),
    items: [
      {
        image: Subscribe,
        step: 1,
        title: t('vpnForLinux:stepCards.subscribe.title'),
        description: t('vpnForLinux:stepCards.subscribe.description'),
      },
      {
        image: DownloadRepository,
        step: 2,
        title: t('vpnForLinux:stepCards.downloadRepository.title'),
        description: (
          <Trans
            i18nKey="vpnForLinux:stepCards.downloadRepository.description"
            components={[<Text.Body2 as="span" fontFamily="courier" fontStyle="italic" key={0} />]}
          />
        ),
      },
      {
        image: SignInAndConnect,
        step: 3,
        title: t('vpnForLinux:stepCards.signInAndConnect.title'),
        description: (
          <Trans
            i18nKey="vpnForLinux:stepCards.signInAndConnect.description"
            components={[<Text.Body2 as="span" fontStyle="italic" key={0} />]}
          />
        ),
      },
    ],
  }),
  benefits: (t) => ({
    title: t('vpnForLinux:benefitsOfLinuxVpn'),
    description: t('vpnForLinux:getAtlasForUbuntu'),
    data: [
      {
        isReversed: true,
        image: getFetchedImage(images, 'product-page/high-speed.png'),
        title: t('vpnForLinux:contentBlocks.fullSpeed.title'),
        description: t('vpnForLinux:contentBlocks.fullSpeed.description'),
        link: {
          title: t('common:links.waysToUseVpn'),
          link: formatLink(t('navigation:paths.use-cases')),
          customEvent: { eventName: GA4_EVENTS.waysToUseVpnClick },
        },
      },
    ],
  }),
  perksBefore: (t) => ({
    items: [
      {
        image: PrivacyPro,
        title: t('vpnForLinux:perkCards.privacyProServers.title'),
        description: (
          <Trans
            i18nKey="vpnForLinux:perkCards.privacyProServers.description"
            components={[
              <LinkWrapper link="/features/safeswap" key={0}>
                <Text.Body2Strong as="span" color="primary" />
              </LinkWrapper>,
              <LinkWrapper link="/features/multihop-plus" key={1}>
                <Text.Body2Strong as="span" color="primary" />
              </LinkWrapper>,
            ]}
          />
        ),
      },
      {
        image: SeamlessStreaming,
        title: t('vpnForLinux:perkCards.seamlessStreaming.title'),
        description: t('vpnForLinux:perkCards.seamlessStreaming.description'),
      },
    ],
  }),
  encryption: (t) => ({
    data: [
      {
        image: getFetchedImage(images, 'product-page/best-in-class-encryption.png'),
        title: t('vpnForLinux:contentBlocks.encryption.title'),
        description: (
          <React.Fragment>
            <span>{t('vpnForLinux:contentBlocks.encryption.description')}</span>
            <Text.Body3 as="span" display="block" mt={16}>
              {t('common:wireGuardTrademark')}
            </Text.Body3>
          </React.Fragment>
        ),
      },
    ],
  }),
  perksAfter: ({ pricingLink = PRICING_LINK, t } = {}) => ({
    items: [
      {
        image: GetOnAllDevices,
        title: t('vpnForLinux:perkCards.getItOnAllDevices.title'),
        description: t('vpnForLinux:perkCards.getItOnAllDevices.description'),
      },
      {
        image: PrivateDns,
        title: t('vpnForLinux:perkCards.privateDns.title'),
        description: t('vpnForLinux:perkCards.privateDns.description'),
      },
    ],
    link: {
      title: t('common:links.seeListFeatures'),
      link: formatLink(t('navigation:paths.features')),
      customEvent: { eventName: GA4_EVENTS.fullFeaturesClick },
    },
  }),
  reviews: (t) => ({
    title: t('vpnForLinux:reviews.title'),
  }),
  pricing: ({ t }) => ({
    title: t('vpnForLinux:pricingSection.title'),
    description: t('vpnForLinux:pricingSection.description'),
    footerProps: {
      text: t('vpnForLinux:pricingSection.notReadyYet'),
      button: {
        link: APPS_LINKS.linux,
        title: t('common:actions.download'),
        buttonProps: {
          customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.linux },
        },
      },
    },
  }),
  faq: () => ({
    title: 'Frequently asked questions',
    items: [
      {
        header: 'How to set up VPN on Linux?',
        content: (
          <ol>
            <li>Click the big blue Download button above and download the installer.</li>
            <li>Open a terminal and run the following command:</li>
            <StyledTextComponent fontFamily="courier" fontStyle="italic">
              sudo dpkg -i /pathToFile/atlasvpn-repo.deb
            </StyledTextComponent>
            <li>
              Replace /pathToFile/ with the location of the downloaded Atlas VPN installer (eg.: if
              it's in the folder Downloads command will be:
            </li>
            <StyledTextComponent fontFamily="courier" fontStyle="italic">
              sudo dpkg -i ~/Downloads/atlasvpn-repo.deb)
            </StyledTextComponent>
            <li>Enter root password and wait for installation to finish</li>
            <li>Update package list:</li>
            <StyledTextComponent fontFamily="courier" fontStyle="italic">
              sudo apt update
            </StyledTextComponent>
            <li>Install Atlas VPN app:</li>
            <StyledTextComponent fontFamily="courier" fontStyle="italic">
              sudo apt install atlasvpn
            </StyledTextComponent>
            <li>Log in to Atlas VPN account:</li>
            <StyledTextComponent fontFamily="courier" fontStyle="italic">
              atlasvpn signin
            </StyledTextComponent>
            <li>Connect to Atlas VPN server:</li>
            <StyledTextComponent fontFamily="courier" fontStyle="italic">
              connect
            </StyledTextComponent>
          </ol>
        ),
      },
      {
        header: 'Do I need a VPN on my Linux?',
        content:
          'Using a VPN on your Linux will ensure that everything you do is secure. Connect to any of our VPN server locations and your IP address will be replaced with a different one. That way you will be able to protect your data on unsecured Wi-Fi networks and enjoy ultra-speed while browsing or watching your favorite content.',
      },
      {
        header: 'Which is the best free VPN for Linux?',
        content:
          'You should look for a VPN service provider that uses strong encryption, maintains good server speed, and has a strict no-logs policy. Atlas VPN aims to be a well-balanced price and features solution that suits everyone.',
      },
      {
        header: 'Which Linux distribution is the most secure?',
        content:
          "It depends and you can find many various opinions on that. But Qubes OS is one of the top  because it's very focused on security. Anyway, all supported distributions used in pairs with Atlas VPN are a perfect combo for ultimate safety and privacy.",
      },
      {
        header: 'Does Linux have a built-in VPN?',
        content:
          "Nope. Linux doesn't have a built-in VPN with a full scale of protection. Most distributions have support for tunneling protocols like OpenVPN, IPSec, or WireGuard, but take advantage of them, you still need an outside server to connect to.",
      },
    ],
  }),
  footerCta: {
    text: ({ pricingLink = PRICING_LINK, t } = {}) => ({
      title: t('vpnForLinux:footerCta.title'),
      description: t('vpnForLinux:footerCta.description'),
    }),
    image: () => ({
      mobile: {
        src: footerImageMobile,
        alt: 'linux page footer',
      },
      mobileTop: {
        src: footerImageMobileTop,
        alt: 'linux page footer',
      },
      tablet: {
        src: footerImageTablet,
        alt: 'linux page footer',
      },
      tabletTop: {
        src: footerImageTabletTop,
        alt: 'linux page footer',
      },
      desktop: {
        src: footerImage,
        alt: 'linux page footer',
        position: {
          bottom: 0,
          height: 540,
          maxWidth: 3000,
        },
      },
    }),
  },
});
